import {
    HttpClient,
    HttpHeaders,
} from '@angular/common/http';

import {
    DomSanitizer,
    SafeUrl,
} from '@angular/platform-browser';

import {
    Observable,
} from 'rxjs';

import {
    map,
} from 'rxjs/operators';


export class SecureImageService {

    constructor(
        private _http: HttpClient,
        private _sanitizer: DomSanitizer,
    ) { }

    public get(url: string): Observable<SafeUrl> {

        return this._http.get(url, { headers: this._getAuthorizationHeaders(), responseType: 'blob' }).pipe(
            map((val) => this._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
        );
    }

    private _getAuthorizationHeaders(): HttpHeaders {

        let headers = new HttpHeaders();

        const accessToken = JSON.parse(localStorage.getItem('auth.token')).accessToken;
        if (accessToken) {
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        return headers;
    }
}
