import {
    Injectable,
} from '@angular/core';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    MatIconRegistry,
} from '@angular/material/icon';


const ASSETS_FOLDER = 'assets/problems/';

@Injectable({
    providedIn: 'root',
})
export class ProblemsAssets {

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        iconRegistry.addSvgIconInNamespace('error', 'not-found', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'not-found.svg'));
    }

}
