import {
    Problem,
    WalletTransactionTransferDetails,
} from '@michel.freiha/ng-sdk';

export class LoadReceiverFromTopupPage {
    public static readonly type: string = '[Topup Page] Load receiver';
    constructor(
        public id: string,
        public mobile: string,
        public currency: string,
    ) { }
}

export class SimulateTransferFromTopupPage {
    public static readonly type: string = '[Topup Page] Transfer money (dry run)';
    constructor(
        public id: string,
        public mobile: string,
        public details: WalletTransactionTransferDetails,
        public currency:string,
    ) { }
}

export class ConfirmTransferFromTopupPage {
    public static readonly type: string = '[Topup Page] Transfer money';
    constructor(
        public id: string,
    ) { }
}

export class UserSDKToken {
    public static readonly type: string = '[User] Generate SDK Token';
    constructor(
        public userId: string,
    ) { }
}

export class UserIDVDocumentStatus {
    public static readonly type: string = '[User] IDV Document Status';
    constructor(
        public userId: string,
    ) { }
}

export class CreateIdvCheck {
    public static readonly type: string = '[User] Create IDV Check';
    constructor(
        public userId: string,
        public agentId: string,
        public reportNames: [],
    ) { }
}

export class GetIdvCheck {
    public static readonly type: string = '[User] Get IDV Check';
    constructor(
        public userId: string,
        public agentId: string,
        public checkId: string,
        public isAgent:boolean
    ) { }
}

export class GetDocumentReport {
    public static readonly type: string = '[User] Get IDV Document Report';
    constructor(
        public reportId: string,
        public userId: string,
    ) { }
}


export class LoadWalletActivitiesFromWalletActivitiesPage {
    public static readonly type: string = '[Wallet Activities Page] Load wallet activities';
}

export class RefreshWalletActivitiesFromWalletActivitiesPage {
    public static readonly type: string = '[Wallet Activities Page] Refresh wallet activities';
}

export class LoadMoreWalletActivitiesFromWalletActivitiesPage {
    public static readonly type: string = '[Wallet Activities Page] Load more wallet activities';
}

export class FailFromApi {
    public static readonly type: string = '[Wallet Activities API] Fail';
    constructor(public problem: Problem) { }
}
