import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  Select,
} from '@ngxs/store';

import {
  Problem,
} from '@michel.freiha/ng-sdk';

// import {
//   DiscoveryService,
//   NYM_DISCOVERY_SERVICE,
// } from '@nymos/discovery';

import {
  ProblemsState,
} from '../../../store/problems.state';


@Component({
  selector: 'nym-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnInit {

  @HostBinding('class.nym-error')
  protected classes: boolean = true;

  @Select(ProblemsState.problem)
  public problem$: Observable<Problem>;

  // protected get isProduction(): boolean { return this._ds.isProduction; }
  protected get isProduction(): boolean { return false; }

  constructor(
    // @Inject(NYM_DISCOVERY_SERVICE) private _ds: DiscoveryService,
  ) { }

  public ngOnInit(): void {  }

}
