import {
  NgModule,
} from '@angular/core';

import {
  HttpClientModule,
} from '@angular/common/http';

import {
  BrowserModule,
} from '@angular/platform-browser';

import {
  BrowserAnimationsModule,
} from '@angular/platform-browser/animations';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  NgxsRouterPluginModule,
} from '@ngxs/router-plugin';

import {
  NgxsReduxDevtoolsPluginModule,
} from '@ngxs/devtools-plugin';

import {
  NgxsStoragePluginModule,
} from '@ngxs/storage-plugin';

import {
  CoreModule,
} from './core/core.module';

import {
  EnvsState,
} from '@nymos/discovery';

import {
  SharedModule,
} from '@nymos/agents/shared';

import {
  AppRoutingModule,
} from './app.routing';

import {
  AppComponent,
} from './core/screens/main/app.component';


@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,


    // Ngxs
    NgxsModule.forRoot([EnvsState], { developmentMode: false }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'auth.token',
        'profile.account',
        'envs.vars',
      ],
    }),

    CoreModule.forRoot(),
    SharedModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
