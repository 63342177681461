import {
    Injectable,
} from '@angular/core';

import {
    CanActivate,
    Router,
} from '@angular/router';

import {
    Store,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';

import {
    AuthState,
} from '../store/auth/auth.state';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private _store: Store,
        private _router: Router,
    ) { }

    public canActivate(): boolean {
        const token = this._store.selectSnapshot(AuthState.accessToken);
        const authed = (token !== undefined);

        if (!authed) {
            this._router.navigate(['/account/signin']);
        }

        return authed;
    }
}
