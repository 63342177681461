import {
    Problem,
} from '@michel.freiha/ng-sdk';

export class LoadBalanceFromTopupPage {
    public static readonly type: string = '[Wallet Transfer Page] Load wallet balance';
    constructor() { }
}

export class RefreshBalanceFromTopupPage {
    public static readonly type: string = '[Wallet Transfer Page] Refresh wallet balance';
}

export class FailFromApi {
    public static readonly type: string = '[Wallet Limits API] Fail';
    constructor(public problem: Problem) { }
}
