import {
    Injectable,
} from '@angular/core';

import {
    CanActivate, Router,
} from '@angular/router';

import {
    Store,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';

import {
    AuthState,
} from '../store/auth/auth.state';


@Injectable()
export class SessionGuard implements CanActivate {
    constructor(
        private _store: Store,
        private _router: Router,
    ) { }

    public canActivate(): boolean {
        const state = this._store.selectSnapshot(AuthState);
        const isAuthing = (!!state.sessionId);
        if (!isAuthing) {
            this._router.navigate(['/account/signin']);
        }

        return isAuthing;
    }
}
