import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {
  CanColor,
  CanColorCtor,
  CanDisable,
  CanDisableCtor,
  CanDisableRipple,
  CanDisableRippleCtor,
  mixinColor,
  mixinDisabled,
  mixinDisableRipple,
} from '@angular/material/core';

import {
  CanShimmer,
  CanShimmerCtor,
  mixinShimmer,
} from '../../../../../core/core';

export class LozengeBase {
  constructor(public _elementRef: ElementRef) { }
}

export const _LozengeMixinBase:
  CanDisableRippleCtor & CanDisableCtor & CanShimmerCtor &  CanColorCtor & typeof LozengeBase =
  mixinColor(mixinShimmer(mixinDisabled(mixinDisableRipple(LozengeBase))));

@Component({
  selector: 'thm-lozenge',
  templateUrl: './lozenge.component.html',
  styleUrls: ['./lozenge.component.scss'],
  inputs: ['disabled', 'disableRipple', 'color', 'thmShimmer', 'thmShimmerShape'], // tslint:disable-line:no-inputs-metadata-property
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LozengeComponent extends _LozengeMixinBase
  implements OnInit, OnDestroy, CanColor, CanShimmer, CanDisable, CanDisableRipple {

  private _rgb: string;

  @HostBinding('class.thm-lozenge')
  @HostBinding('class.mat-caption')
  protected classes: boolean = true;

  @Input()
  get rgb(): string { return this._rgb; }
  set rgb(value: string) { this._toRgb(value); }

  constructor(
    elementRef: ElementRef,
  ) {
    super(elementRef);
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void { }

  public _toRgb(value: string): any {
    const rgb = value;

    if (!rgb)
      return;

    this._elementRef.nativeElement.style.backgroundColor = rgb;
    this._rgb = rgb;
  }
}
