import * as camelcase from 'camelcase';

import {
  WalletTransaction,
} from '@michel.freiha/ng-sdk';

import {
  Category,
  Status,
  WalletActivity,
} from '../models/wallet-activity.model';



export class WalletActivityBuilder {

  private _transaction: WalletTransaction;

  public withTransaction(value: WalletTransaction): WalletActivityBuilder {
    this._transaction = value;
    return this;
  }

  public build(): WalletActivity {

    if (!this._transaction)
      return undefined;

    const id = this._transaction.id;
    const status = this._status();
    const category = this._category();
    const type = `${category.value}-${status.value}`;
    const currency = this._transaction.currency.toUpperCase();
    const total = this._transaction.totalAmount;
    const receiver = this._transaction.receiver.displayName || this._transaction.receiver.mobile;
    let sender = this._transaction.sender.displayName || this._transaction.sender.mobile;

    if(this._transaction.description.toLowerCase()==="travel card issuance"){
      status.color= 'mat-orange';
      if(this._transaction.references && this._transaction.references.account){
        sender = this._transaction.references.account.displayName+' '+this._transaction.references.account.mobile;
      }
      else{
        sender= "unknown";
      }

    }

    return new WalletActivity({
      id: id,
      type: type,
      status: status,
      category: category,
      currency: currency,
      total: total,
      receiver: receiver,
      sender: sender,
    });
  }

  private _category(): { icon: string, value: Category } {
    const value = camelcase(this._transaction.category) as Category || undefined;

    switch (this._transaction.category) {
      case 'wallet_credit':
      case 'wallet_debit':
        return { icon: 'send', value: value };

      case 'card_issuance':
      case 'card_reload':
        return { icon: undefined, value: value };

      case 'fund_send':
      case 'fund_receive':
        return { icon: 'send', value: value };

      default:
        return undefined;
    }
  }

  private _status(): { icon: string, color: string, value: Status } {
    switch (this._transaction.status) {
      case 'cancelled':
      case 'cancelling':
      case 'declined':
      case 'error':
        return { icon: 'block', color: 'mat-red', value: 'failed' };

      case 'processing':
        return { icon: 'hourglass_empty', color: 'mat-yellow', value: 'processing' };

      case 'settled':
        return { icon: 'done', color: 'mat-green', value: 'success' };


      default:
        return { icon: 'device_unknown', color: 'mat-primary', value: undefined };
    }
  }

}
