/* tslint:disable:member-ordering */

import {
  Injectable,
} from '@angular/core';

import {
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

import {
  Directionality,
} from '@angular/cdk/bidi';

import {
  Store,
} from '@ngxs/store';

import {
  Notification,
} from '../models/notification.model';

@Injectable()
export class NotificationCenter {
  private readonly defaultTitle: string = '';
  private readonly defaultAction: any = () => this._snackbar.dismiss();

  public get snackbar(): MatSnackBar {
    return this._snackbar;
  }

  constructor(
    private _snackbar: MatSnackBar,
    private _dir: Directionality,
    private _store: Store,
  ) { }

  public show(notification: Notification): void {
    const message = notification.message;
    const title = this._titleFrom(notification);
    const action = this._actionFrom(notification);
    const config = this._configFrom(notification);

    const snackBarRef = this._snackbar.open(message, title, config);

    snackBarRef.onAction().subscribe(action);
  }

  public dismiss(): void {
    this._snackbar.dismiss();
  }

  private _titleFrom(notification: Notification): string {
    return notification.button ? notification.button : this.defaultTitle;
  }

  private _actionFrom(notification: Notification): any {
    const action = () => this._store.dispatch(notification.action);
    return notification.action ? action : this.defaultAction;
  }

  private _configFrom(notification: Notification): MatSnackBarConfig {
    let snackBarConfig = new MatSnackBarConfig();

    const autoHide = notification.config && notification.config.autoHide;

    snackBarConfig.duration = autoHide ? notification.config.duration : 0;
    snackBarConfig.panelClass = notification.button ? [notification.type] : undefined;
    snackBarConfig.direction = this._dir.value;

    return snackBarConfig;
  }
}
