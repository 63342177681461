export type Status =
  | 'success'
  | 'processing'
  | 'failed'
  | undefined;

export type Category =
  | 'credit'
  | 'debit'
  | 'cardIssuance'
  | 'cardReload'
  | 'transfer'
  | undefined;

export class WalletActivity {

  public id: string;
  public status: { icon: string, color: string, value: string };
  public category: { icon: string, value: string };
  public type: string;
  public currency: string;
  public total: number;
  public sender: string;
  public receiver: string;

  constructor(ob?: Partial<WalletActivity>) {

    this.id = undefined;
    this.status = undefined;
    this.category = undefined;
    this.type = undefined;
    this.currency = undefined;
    this.total = undefined;
    this.sender = undefined;
    this.receiver = undefined;

    if (!ob) { return; }

    if (ob.id !== undefined) { this.id = ob.id; }
    if (ob.status !== undefined) { this.status = ob.status; }
    if (ob.category !== undefined) { this.category = ob.category; }
    if (ob.type !== undefined) { this.type = ob.type; }
    if (ob.currency !== undefined) { this.currency = ob.currency; }
    if (ob.total !== undefined) { this.total = ob.total; }
    if (ob.sender !== undefined) { this.sender = ob.sender; }
    if (ob.receiver !== undefined) { this.receiver = ob.receiver; }
  }
}

