// tslint:disable:variable-name

import { Notification } from '../../notification/models/notification.model';
import { Texts } from '../../texts/texts';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Failure: Notification = new Notification(Texts.Actions.Failure);
    public static readonly SdkTokenFailure: Notification = new Notification(Texts.Actions.SdkTokenFailure);
    public static readonly IdvDocumentStatus: Notification = new Notification(Texts.Actions.IdvDocumentStatus);
    public static readonly CheckIdvNotMatchResult: Notification = new Notification(Texts.Actions.CheckIdvNotMatchResult);
    public static readonly CheckIdvMatchResult: Notification = new Notification(Texts.Actions.CheckIdvMatchResult);
}


