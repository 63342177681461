import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  NgForm,
} from '@angular/forms';

import {
  MAT_DIALOG_DATA as DIALOG_DATA,
} from '@angular/material/dialog';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  DialogRef,
} from '../../dialog.ref';

@Component({
  selector: 'thm-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('ngForm', { static: true })
  protected ngForm: NgForm;

  @HostBinding('class.thm-modal')
  protected classes: boolean = true;

  protected acting$: Observable<boolean>;

  protected form: FormGroup;
  protected subscription: Subscription = new Subscription();

  constructor(
    protected _fb: FormBuilder,
    protected _ref: DialogRef<any, any>,
    protected _cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale?: string,
    @Inject(DIALOG_DATA) protected data?: any,
  ) { }

  public ngOnInit(): void { }

  public ngAfterViewInit(): void {

    if (!this.form)
      this.initForm(this.data);

    this.subscription.add(this._ref.submitted().subscribe(() => {
      if (this.ngForm) {
        this.ngForm.onSubmit(undefined);
        this._cd.markForCheck();
      }
      this.submit();
    }));

    this.subscription.add(this.form.statusChanges.subscribe((status) => {
      this._ref.formValid = (status === 'VALID');
      this._cd.markForCheck();
    }));

    this.subscription.add(this.acting$.pipe(filter((a) => a !== undefined)).subscribe((acting) => {
      this._ref.onAction = acting;
      this._cd.markForCheck();
    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // @Override
  protected initForm(data: any): void { }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    this._ref.close();
  }

}
