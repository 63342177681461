import {
  WalletTransaction,
} from '@michel.freiha/ng-sdk';

import {
  WalletActivities,
} from '../models/wallet-activities.model';

import {
  WalletActivityBuilder,
} from './wallet-activity.builder';

export class WalletActivitiesBuilder {

  private _transactions: WalletTransaction[];

  public withTransactions(value: WalletTransaction[]): WalletActivitiesBuilder {
    this._transactions = value;
    return this;
  }

  public build(): WalletActivities[] {

    if (!this._transactions)
      return undefined;

    const activities = this._transactions.reduce((obj, item) => {
      let date = item.modified.toISOString().slice(0, 10);
      obj[date] = obj[date] ? obj[date] : { date: new Date(date), items: [] };
      obj[date].items.push(new WalletActivityBuilder().withTransaction(item).build());
      return obj;
    }, {});

    return Object.keys(activities).sort((a, b) => (a > b ? -1 : 1)).map((k) => activities[k]);
  }

}
