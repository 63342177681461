import {
  Wallet,
} from '@michel.freiha/ng-sdk';

import {
  WalletBalance,
} from '../models/wallet-balance.model';


export class WalletBalanceBuilder {

  private _wallets: Wallet[];

  public withWallets(value: Wallet[]): WalletBalanceBuilder {
    this._wallets = value;
    return this;
  }

  public build(): any {

    let currency: string;
    let amount: number;
    let wallet_list=[];

    if (!this._wallets && (this._wallets.length < 1))
      return undefined;

    if (this._wallets.length > 1)
         console.warn(`Found ${this._wallets.length} wallets. Using the first one.`);

    const wallet = this._wallets;
    let  result=[];  
    for(var i =0;i<wallet.length;i++){
      const result   =   Object.keys(wallet[i].balances).map((k) => ({ currency: k.toUpperCase(), amount: wallet[i].balances[k] }));
        wallet_list.push(result[0])
    }

    //result = Object.keys(balances).map((k) => ({ currency: k.toUpperCase(), amount: balances[k] }));

    if (wallet_list.length < 1)
      return undefined;

    if (wallet_list.length > 1)
      console.warn(`Found ${wallet_list.length} balances. Using the first one.`);
    return wallet_list;
  }
}
