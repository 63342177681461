import {
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';

import {
  Directionality,
} from '@angular/cdk/bidi';

import {
  Overlay,
  OverlayConfig,
  OverlayContainer,
  OverlayRef,
  ScrollStrategy,
} from '@angular/cdk/overlay';

import {
  ComponentType,
  PortalInjector,
} from '@angular/cdk/portal';

import {
  MAT_DIALOG_DATA as DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS as DIALOG_DEFAULT_OPTIONS,
  MAT_DIALOG_SCROLL_STRATEGY as DIALOG_SCROLL_STRATEGY,
  MatDialogConfig as DialogConfig,
} from '@angular/material/dialog';

import {
  defer,
  Observable,
  of,
  Subject,
} from 'rxjs';

import {
  startWith,
} from 'rxjs/operators';

import {
  DialogContainer,
} from '../outlets/dialog.container';

import {
  DialogRef,
} from '../dialog.ref';

import {
  CreateDialog,
} from '../create-dialog';


@Injectable()
export class DialogService implements OnDestroy {

  private _scrollStrategy: () => ScrollStrategy;

  private _openDialogsAtThisLevel: DialogRef<any>[] = [];
  private _ariaHiddenElements: Map<Element, string | null> = new Map();

  private readonly _afterAllClosedAtThisLevel: Subject<void> = new Subject();
  private readonly _afterOpenedAtThisLevel: Subject<DialogRef<any>> = new Subject();

  private get openDialogs(): DialogRef<any>[] {
    return this._parentDialog ? this._parentDialog.openDialogs : this._openDialogsAtThisLevel;
  }

  private get afterOpened(): Subject<DialogRef<any>> {
    return this._parentDialog ? this._parentDialog.afterOpened : this._afterOpenedAtThisLevel;
  }

  private get _afterAllClosed(): Subject<void> {
    const parent = this._parentDialog;
    return parent ? parent._afterAllClosed : this._afterAllClosedAtThisLevel;
  }

  public readonly afterAllClosed: Observable<void> = defer(() => this.openDialogs.length ?
    this._afterAllClosed :
    this._afterAllClosed.pipe(startWith(undefined))) as Observable<any>;

  constructor(
    @Optional() @SkipSelf() private _parentDialog: DialogService,
    private _overlay: Overlay,
    private _injector: Injector,
    private _overlayContainer: OverlayContainer,
    @Optional() @Inject(DIALOG_DEFAULT_OPTIONS) private _defaultOptions: DialogConfig,
    @Optional() @Inject(DIALOG_SCROLL_STRATEGY) scrollStrategy: any,
  ) {
    this._scrollStrategy = scrollStrategy;
  }

  public ngOnDestroy(): void {
    this._closeDialogs(this._openDialogsAtThisLevel);
    this._afterAllClosedAtThisLevel.complete();
    this._afterOpenedAtThisLevel.complete();
  }

  public open<C extends DialogContainer, T, D = any, R = any>(
    factory: CreateDialog,
    container: ComponentType<C>,
    content: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig<D>,
  ): DialogRef<T, R> {

    config = this._applyConfigDefaults(config, this._defaultOptions || new DialogConfig());

    if (config.id && this.getDialogById(config.id)) {
      throw Error(`Dialog with id "${config.id}" exists already. The dialog id must be unique.`);
    }

    const overlay = this._createOverlay(config);
    const ref = factory.createDialog(overlay, container, content, config);

    if (!this.openDialogs.length) {
      this._hideNonDialogContentFromAssistiveTechnology();
    }

    this.openDialogs.push(ref);
    ref.afterClosed().subscribe(() => this._removeOpenDialog(ref));
    this.afterOpened.next(ref);

    return ref;
  }

  public closeAll(): void {
    this._closeDialogs(this.openDialogs);
  }

  public getDialogById(id: string): DialogRef<any> | undefined {
    return this.openDialogs.find((dialog) => dialog.id === id);
  }

  public createInjector<T>(
    config: DialogConfig,
    dialogRef: DialogRef<T>,
    dialogContainer: DialogContainer): PortalInjector {

    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;

    const injectionTokens = new WeakMap();
    injectionTokens.set(DialogConfig, config);
    injectionTokens.set(DialogContainer, dialogContainer);
    injectionTokens.set(DIALOG_DATA, config.data);
    injectionTokens.set(DialogRef, dialogRef);

    if (config.direction && (!userInjector || !userInjector.get<Directionality | null>(Directionality, null))) {
      injectionTokens.set(Directionality, { value: config.direction, change: of() });
    }

    return new PortalInjector(userInjector || this._injector, injectionTokens);
  }

  private _createOverlay(config: DialogConfig): OverlayRef {
    const overlayConfig = this._getOverlayConfig(config);
    return this._overlay.create(overlayConfig);
  }

  private _getOverlayConfig(dialogConfig: DialogConfig): OverlayConfig {
    const state = new OverlayConfig({
      positionStrategy: this._overlay.position().global(),
      scrollStrategy: dialogConfig.scrollStrategy || this._scrollStrategy(),
      panelClass: dialogConfig.panelClass,
      hasBackdrop: dialogConfig.hasBackdrop,
      direction: dialogConfig.direction,
      minWidth: dialogConfig.minWidth,
      minHeight: dialogConfig.minHeight,
      maxWidth: dialogConfig.maxWidth,
      maxHeight: dialogConfig.maxHeight,
      disposeOnNavigation: dialogConfig.closeOnNavigation,
    });

    if (dialogConfig.backdropClass) {
      state.backdropClass = dialogConfig.backdropClass;
    }

    return state;
  }


  private _removeOpenDialog(dialogRef: DialogRef<any>): void {
    const index = this.openDialogs.indexOf(dialogRef);

    if (index > -1) {
      this.openDialogs.splice(index, 1);

      if (!this.openDialogs.length) {
        this._ariaHiddenElements.forEach((previousValue, element) => {
          if (previousValue) {
            element.setAttribute('aria-hidden', previousValue);
          } else {
            element.removeAttribute('aria-hidden');
          }
        });

        this._ariaHiddenElements.clear();
        this._afterAllClosed.next();
      }
    }
  }

  private _hideNonDialogContentFromAssistiveTechnology(): void {
    const overlayContainer = this._overlayContainer.getContainerElement();

    if (overlayContainer.parentElement) {
      const siblings = overlayContainer.parentElement.children;

      for (let i = siblings.length - 1; i > -1; i--) {
        let sibling = siblings[i];

        if (sibling !== overlayContainer &&
          sibling.nodeName !== 'SCRIPT' &&
          sibling.nodeName !== 'STYLE' &&
          !sibling.hasAttribute('aria-live')) {

          this._ariaHiddenElements.set(sibling, sibling.getAttribute('aria-hidden'));
          sibling.setAttribute('aria-hidden', 'true');
        }
      }
    }
  }

  private _closeDialogs(dialogs: DialogRef<any>[]): void {
    let i = dialogs.length;

    while (i--) {
      dialogs[i].close();
    }
  }

  private _applyConfigDefaults(config?: DialogConfig, defaultOptions?: DialogConfig): DialogConfig {
    return { ...defaultOptions, ...config };
  }
}

