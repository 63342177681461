import {
    WalletTransaction,
    WalletTransactionReceiver,
} from '@michel.freiha/ng-sdk';

import {
    WalletTransfer,
} from '../models/wallet-transfer.model';



export class WalletTransferBuilder {

    private _transaction: WalletTransaction;
    private _receiver: WalletTransactionReceiver;

    public withReceiver(value: WalletTransactionReceiver): WalletTransferBuilder {
        this._receiver = value;
        return this;
    }

    public withTransaction(value: WalletTransaction): WalletTransferBuilder {
        this._transaction = value;
        return this;
    }

    public build(): WalletTransfer {

        let id: string;
        let accountId:string
        let receiver: string;
        let receiverID: string;
        let friendlyId: string;
        let status: string;
        let currency: string;
        let amount: number;
        let fees: number;
        let total: number;
        let limit: number;

        if (!this._transaction && !this._receiver)
            return undefined;

        if (this._receiver) {
            currency = this._receiver.limit.currency.toUpperCase();
            receiver = this._receiver.account.displayName || this._receiver.account.mobile;
            receiverID = this._receiver.account.id || this._receiver.account.mobile;
            limit = this._receiver.limit.available;
        }

        if (this._transaction) {
            id = this._transaction.id;
            accountId= this._transaction.accountId;
            receiver = this._transaction.receiver.displayName || this._transaction.receiver.mobile;
            friendlyId = this._transaction.friendlyId.toUpperCase();
            status = this._transaction.status;
            currency = this._transaction.currency.toUpperCase();
            amount = this._transaction.amount;
            fees = this._transaction.totalFees;
            total = this._transaction.totalAmount;
        }

        return new WalletTransfer({
            id: id,
            //accountId: this._transaction.accountId,
            friendlyId: friendlyId,
            receiver: receiver,
            receiverID:receiverID,
            status: status as any,
            currency: currency,
            amount: amount,
            fees: fees,
            total: total,
            limit: limit,
        });
    }
}
