import {
  NgModule,
} from '@angular/core';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  NotificationModule,
} from '../notification/notification.module';

import {
    STATES_MODULES,
} from './store.config';

@NgModule({
    imports: [
        NotificationModule,
        NgxsModule.forFeature(STATES_MODULES),
    ],
    exports: [NgxsModule],
})
export class StoreModule { }
