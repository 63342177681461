import {
  Component,
  OnInit,
} from '@angular/core';

import {
  ThemeAssets,
} from '@nymos/theme';

import {
  ProblemsAssets,
} from '@nymos/problems';

import {
  ConsoleAssets,
} from '../../../console/console.assets';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    themeAssets: ThemeAssets,
    problemsAssets: ProblemsAssets,
    consoleAssets: ConsoleAssets,
  ) { }

  public ngOnInit(): void { }
}
