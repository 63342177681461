export class WalletBalance {

  public currency: string;
  public amount: number;

  constructor(ob?: Partial<WalletBalance>) {

    this.currency = undefined;
    this.amount = undefined;

    if (!ob) { return; }

    if (ob.currency !== undefined) { this.currency = ob.currency; }
    if (ob.amount !== undefined) { this.amount = ob.amount; }
  }
}

