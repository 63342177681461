import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'info, thm-info, .thm-info, [thm-info], [thmInfo]',
})
export class InfoDirective {

  @HostBinding('class.thm-info')
  @HostBinding('class.mat-caption')
  protected classes: boolean = true;

  constructor() { }

}
