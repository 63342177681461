import {
    Params,
} from '@angular/router';

import {
    Observable,
    ReplaySubject,
    Subject,
} from 'rxjs';

import {
    // SearchCriteria,
    // SearchFilter,
    // SearchMatcher,
    // SortDirection,
} from '@michel.freiha/ng-sdk';

import {
    SearchSnapshot,
} from '../../shared';



export class SearchBuilder {

    private _search$: Subject<SearchSnapshot> = new ReplaySubject();

    private _criteria: any = {};

    private _query?: string;
    private _sortBy?: string;
    private _orderBy?: any;
    private _matcher?: any;
    private _filters?: { [field: string]: any } = {};

    public get params(): Params { return this._toParams(); }
    public set params(params: Params) { this._fromParams(params); }

    public get criteria(): any { return this._criteria; }

    public get isEmpty(): boolean { return Object.entries(this._criteria).length === 0; }

    public get hasQuery(): boolean { return !!this._query; }

    constructor(params?: Params) {
        this._fromParams(params);
    }

    public build(): Observable<SearchSnapshot> {
        return this._search$.asObservable();
    }

    public query(query: string): void {
        this._query = query;
        this._emit();
    }

    public sortBy(sortBy: string): void {
        this._sortBy = sortBy;
        this._emit();
    }

    public orderBy(orderBy: any): void {
        this._orderBy = orderBy;
        this._emit();
    }

    public matcher(matcher: any): void {
        this._matcher = matcher;
        this._emit();
    }

    public addFilter(filter: any): void {
        if (!filter)
            return;

        if (filter.values && filter.values.length > 0)
            this._filters[filter.field] = filter;
        else
            delete this._filters[filter.field];

        this._emit();
    }

    public reset(): void {
        this._query = undefined;
        this._sortBy = undefined;
        this._orderBy = undefined;
        this._matcher = undefined;
        this._filters = {};

        this._emit();
    }

    private _emit(): void {
        this._update();
        this._search$.next({criteria: this.criteria, params: this.params, isEmpty: this.isEmpty});
    }

    private _update(): void {
        const criteria = <any>{};

        if (this._query)
            criteria.query = this._query;

        if (this._sortBy)
            criteria.sortBy = this._sortBy;

        if (this._orderBy)
            criteria.orderBy = this._orderBy;

        if (this._matcher)
            criteria.matcher = this._matcher;

        if (Object.entries(this._filters).length)
            criteria.filters = Object.values(this._filters);

        this._criteria = criteria;
    }

    private _fromParams(params: Params): void {
        this._query = params && params.query;
        this._emit();
    }

    private _toParams(): Params {
        return {
            query: this.criteria.query,
        };
    }

}

