import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  Store,
} from '@ngxs/store';

import {
  RouterStateSerializer,
} from '@ngxs/router-plugin';

import {
  ACCESS_TOKEN_STORAGE_KEY,
  AGENTS_OPTIONS,
  CORE_OPTIONS,
  NymAgentsModule,
  NymCoreModule,
  NymOauth2Module,
  NymOauth2RefreshModule,
  NymOauth2ScopeModule,
  NymUsersModule,
  NymWalletsModule,
  OAUTH2_OPTIONS,
  OAUTH2_SCOPE_OPTIONS,
  USERS_OPTIONS,
  WALLETS_OPTIONS,
} from '@michel.freiha/ng-sdk';

import {
  NYM_DISCOVERY_SERVICE,
  NymDiscoveryModule,
} from '@nymos/discovery';

import {
  NymProblemsModule,
} from '@nymos/problems';

import {
  NymAuthModule,
} from '@nymos/auth';

import {
  agentsOptionsFactory,
  coreOptionsFactory,
  device,
  oauth2OptionsFactory,
  oauth2ScopesOptionsFactory,
  usersOptionsFactory,
  walletsOptionsFactory,
} from './nymos.sdk';

import {
  CustomRouterStateSerializer,
} from './router.serializer';



@NgModule({
  imports: [
    CommonModule,

    // Backend
    NymCoreModule.forRoot(),
    NymOauth2Module.forRoot(),
    NymOauth2RefreshModule.forRoot(),
    NymOauth2ScopeModule.forRoot(),
    NymAgentsModule.forRoot(),
    NymWalletsModule.forRoot(),
    NymUsersModule.forRoot(),

    // Framework
    NymAuthModule.forRoot(),
    NymDiscoveryModule.forRoot(),
    NymProblemsModule.forRoot(),
  ],
  declarations: [],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule,
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only',
      );
    }
  }

  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        { provide: CORE_OPTIONS, useFactory: coreOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: OAUTH2_OPTIONS, useFactory: oauth2OptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: OAUTH2_SCOPE_OPTIONS, useFactory: oauth2ScopesOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: AGENTS_OPTIONS, useFactory: agentsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: WALLETS_OPTIONS, useFactory: walletsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: USERS_OPTIONS,  useFactory: usersOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE]  },
        { provide: ACCESS_TOKEN_STORAGE_KEY, useValue: 'auth.token' },
       
      ],
    };
  }
}

