import {
    Store,
} from '@ngxs/store';

import * as uuid from 'uuid';

import {
    AgentsClientBuilder,
    BearerCredentials,
    ClientParams,
    CoreClientBuilder,
    DefaultUrlFactory,
    EmptyCredentials,
    Oauth2ClientBuilder,
    Oauth2ScopeClientBuilder,
    UsersClientBuilder,
    WalletsClientBuilder,
} from '@michel.freiha/ng-sdk';

import {
    DiscoveryService,
    EnvsState,
} from '@nymos/discovery';

// export const device: Device = new Device({
//     id: 'ANGULAR-012345678901234567980123456798',
//     name: 'name', // CONSTANT
//     platform: new DevicePlatform({ os: DeviceOS.Android }),
// });

export const device = ({
    id: localStorage.getItem('deviceId'),
    name: 'name', // CONSTANT
    platform: { os: 'web' },
});


const clientParams = (appId: string, consumerKey: string) => {
    if (!device.id) {
        let id = uuid.v4();
        localStorage.setItem('deviceId', id);
        device.id = id;
    }
    return new ClientParams(
        appId,
        '1.1.0',
        device.id,
        null,
        null,
        null,
        consumerKey,
    );
};

export const coreOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    return new CoreClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('core')))
        .withCredentials(new EmptyCredentials())
        .withClientParams(clientParams(appId, consumerKey))
        .build();
};

export const oauth2OptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    return new Oauth2ClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('oauth2')))
        .withCredentials(new EmptyCredentials())
        .withClientParams(clientParams(appId, consumerKey))
        .build();
};

export const oauth2ScopesOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new Oauth2ScopeClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('oauth2')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(appId, consumerKey))
        .build();
};
export const agentsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new AgentsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('agents')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(appId, consumerKey))
        .build();
};

export const walletsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new WalletsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('wallets')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(appId, consumerKey))
        .build();
};

export const usersOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new UsersClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('users')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(appId, consumerKey))
        .build();
};
