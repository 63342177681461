/* tslint:disable: member-ordering */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import {
  SafeUrl,
} from '@angular/platform-browser';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
  coerceBooleanProperty,
} from '@angular/cdk/coercion';

import {
  CanColor,
  CanColorCtor,
  mixinColor,
  ThemePalette,
} from '@angular/material/core';

import {
  EMPTY,
  Observable,
} from 'rxjs';

import {
  catchError,
} from 'rxjs/operators';

import {
  SecureImageService,
} from '../../../../../core/services/secure-image.service';

import {
  thmAnimation,
} from '../../animations/thumbnail.animations';


export class ThumbnailBase {
  constructor(public _elementRef: ElementRef<HTMLInputElement>, public _renderer: Renderer2) { }
}

export const _ThumbnailMixinBase: CanColorCtor & typeof ThumbnailBase = mixinColor(ThumbnailBase);

const DEFAULT_COLOR: ThemePalette = 'primary' as ThemePalette;

@Component({
  selector: 'thm-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  inputs: ['color'], // tslint:disable-line:no-inputs-metadata-property
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    thmAnimation.loading,
  ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ThumbnailComponent, multi: true },
  ],
})
export class ThumbnailComponent extends _ThumbnailMixinBase
  implements OnInit, OnChanges, CanColor, ControlValueAccessor {

  private _error: boolean = false;
  private _uploadable: boolean = false;

  private _file: File | null = null;
  private _onChange: Function;


  @HostBinding('class.thm-thumbnail')
  protected classes: boolean = true;

  @HostListener('change', ['$event.target.files'])
  protected emitFiles(event: FileList): void { this._emitFiles(event); }

  @Output('upload') // tslint:disable-line:no-output-rename
  protected uploadEmitter: EventEmitter<File> = new EventEmitter();

  @Output('view') // tslint:disable-line:no-output-rename
  protected viewEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  public name: string;

  @Input()
  public url: string;

  @Input()
  public get uploadable(): boolean { return this._uploadable; }
  public set uploadable(value: boolean) { this._uploadable = coerceBooleanProperty(value); }

  public url$: Observable<SafeUrl>;

  protected get error(): boolean { return this._error; }
  protected get file(): File { return this._file; }

  constructor(
    private _secureService: SecureImageService,
    private _cd: ChangeDetectorRef,
    elementRef: ElementRef<HTMLInputElement>,
    renderer: Renderer2,
  ) {
    super(elementRef, renderer);
    this.color = DEFAULT_COLOR;
  }

  public ngOnInit(): void {
    this._uploadable = this.uploadEmitter.observers.length > 0;
  }

  public ngOnChanges(): void {

    if (this.url) {
      this.url$ = this._secureService.get(this.url).pipe(
        catchError((e) => {
          this._error = true;
          this._cd.markForCheck();
          return EMPTY;
        }),
      );
    }

  }

  public writeValue(obj: any): void {
    this._elementRef.nativeElement.value = '';
    this._file = null;
  }

  public registerOnChange(fn: Function): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void { }

  protected view(): void {
    this.viewEmitter.emit();
  }

  protected clear(): void {
    this.url = '';
    this._file = null;
  }

  private _emitFiles(event: FileList): void {
    const file = event && event.item(0);
    this._onChange(file);
    this._file = file;

    this.uploadEmitter.emit(this._file);
  }

}
