import { isBoolean } from 'util';

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';


@Component({
  selector: 'thm-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {

  @HostBinding('class.thm-loader')
  protected classes: boolean = true;

  @Input()
  public loading: boolean;

  @Input()
  public data: boolean | any[] = true;

  public get hasData(): boolean { return (isBoolean(this.data) && !!this.data) || (!!Array.isArray(this.data) && this.data.length > 0); }

  constructor() { }

  public ngOnInit(): void { }
}
