import {
    NgModule,
} from '@angular/core';

import {
    RouterModule,
    Routes,
} from '@angular/router';


import {
    SessionGuard,
} from './guards/session.guard';

import {
    AuthComponent,
} from './views/layouts/auth/auth.component';

import {
    SignInComponent,
} from './views/containers/sign-in/sign-in.component';

import {
    MobileVerificationComponent,
} from './views/containers/mobile-verification/mobile-verification.component';

export const routes: Routes = [
    {
        path: 'account',
        component: AuthComponent,
        children: [
            { path: 'signin', component: SignInComponent },
            { path: 'challenge/ipp', component: MobileVerificationComponent, canActivate: [SessionGuard] },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule { }
