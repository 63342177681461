import {
    Optional,
    Provider,
    SkipSelf,
} from '@angular/core';

import {
    MatSnackBar,
} from '@angular/material/snack-bar';

import {
    Directionality,
} from '@angular/cdk/bidi';

import {
    Store,
} from '@ngxs/store';

import {
    NotificationCenter,
} from './services/notification.service';


export const notificationServiceFactory = (
    service: NotificationCenter,
    store: Store,
    snackbar: MatSnackBar,
    dir: Directionality,
) => {
    return service || new NotificationCenter(snackbar, dir, store);
};

export const notificationProvider: Provider = {
    provide: NotificationCenter,
    useFactory: notificationServiceFactory,
    deps: [
        [new Optional(), new SkipSelf(), NotificationCenter],
        [Store],
        [MatSnackBar],
        [Directionality],
    ],
};
