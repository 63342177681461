import * as camelcase from 'camelcase';

import {
    Injectable,
} from '@angular/core';

import {
    FormGroup, ValidationErrors,
} from '@angular/forms';

import {
    ConflictProblem,
    ConstraintViolationProblem,
    Problem,
    ValidationProblem,
} from '@michel.freiha/ng-sdk';


interface Field {
    from: string;
    to: string;
}

@Injectable({
    providedIn: 'root',
})
export class ProblemHandler {

    public handle(problem: Problem, form: FormGroup, stringOrFields: (string | Field)[] = []): void {

        if (!problem)
            return;

        let fields = this._toFields(stringOrFields);

        if (this._handleProblemsWithFields(problem, form, fields))
            return;

        if (this._handleProblemsWithDetails(problem, form, fields))
            return;

        this._handleProblems(problem, form, fields);
    }

    private _handleProblemsWithFields(problem: Problem, form: FormGroup, fields: Field[]): boolean {
        const fillError = (v) => {
            const id = camelcase.default(v.field.split('.').pop());

            if (!!form.controls[v.field])
                return form.controls[v.field].setErrors(this.getErrors(problem, v.message));

            if (!!form.controls[id])
                return form.controls[id].setErrors(this.getErrors(problem, v.message));

            const field = fields.find((e) => e.from === id);
            if (field && !!form.controls[field.to])
                return form.controls[field.to].setErrors(this.getErrors(problem, v.message));

            const errors = form.errors || {};
            form.setErrors({ ...errors, [id]: this.getErrors(problem, v.message) });
        };

        switch (problem.constructor) {
            case ConstraintViolationProblem: {
                (problem as ConstraintViolationProblem).violations.forEach(fillError);
                return true;
            }

            case ValidationProblem: {
                (problem as ValidationProblem).fields.forEach(fillError);
                return true;
            }

            case ConflictProblem: {
                (problem as ConflictProblem).fields.forEach(fillError);
                return true;
            }

            default:
                return false;
        }
    }

    private _handleProblemsWithDetails(problem: Problem, form: FormGroup, fields: Field[]): boolean {

        if (!problem.detail)
            return false;

        if (fields.length > 0) {
            fields.forEach((f) => {
                if (!!form.controls[f.to])
                    form.controls[f.to].setErrors(this.getErrors(problem));
            });
        } else {
            form.setErrors(this.getErrors(problem));
        }

        return true;

    }

    private _handleProblems(problem: Problem, form: FormGroup, fields: Field[]): boolean {

        let type = camelcase.default(problem.type.split('/').pop());

        let obj = {};
        obj[type] = true;

        if (fields.length > 0) {
            fields.forEach((f) => {
                if (!!form.controls[f.to]) {
                    form.controls[f.to].setErrors(this.getErrors(problem));
                }
            });
        } else {
            form.setErrors(obj);
        }

        return true;
    }

    private _toFields(params: (string | Field)[]): Field[] {
        return params.map((p) => {
            if (typeof p === 'string') return { from: p, to: p };
            return p;
        });
    }

    private getErrors(problem: Problem, message?: string): ValidationErrors {
        const errors: ValidationErrors = {};
        errors[`server::${problem.status}`] = { message: message || problem.detail, problem: problem };
        return errors;
    }
}
