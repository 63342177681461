import {
    AgentAccountSessionCustomToken as CustomToken,
    Oauth2AccessToken,
    Problem,
} from '@michel.freiha/ng-sdk';



export class Init {
    public static readonly type: string = '[Auth] Init auth';
}

export class InitSession {
    public static readonly type: string = '[Auth] Init session';
    constructor(public mobile: string) { }
}

export class ConfirmSession {
    public static readonly type: string = '[Auth] Confirm session';
    constructor(public code: string) { }
}

export class SignIn {
    public static readonly type: string = '[Auth] Sign in';
    constructor(public customToken: CustomToken) { }
}

export class SignOut {
    public static readonly type: string = '[Auth] Sign out';
}

export class RefreshToken {
    public static readonly type: string = '[Auth] Refresh token';
    constructor(public token: Oauth2AccessToken) { }
}

export class AuthFail {
    public static readonly type: string = '[Auth] Flow fail';
    constructor(public problem: Problem) { }
}
