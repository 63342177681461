import {
    Optional,
    Provider,
    SkipSelf,
} from '@angular/core';

import {
    Injectable,
} from '@angular/core';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    MatIconRegistry,
} from '@angular/material/icon';


const ASSETS_FOLDER = 'assets/theme/';

@Injectable()
export class ThemeAssets {

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        iconRegistry.addSvgIconInNamespace('theme', 'empty-box', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'empty-box.svg'));
        iconRegistry.addSvgIconInNamespace('creditcard', 'default', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'creditcard/default.svg'));
    }

}


export const themeAssetsFactory = (
    service: ThemeAssets,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
) => {
    return service || new ThemeAssets(iconRegistry, sanitizer);
};

export const themeAssetsProvider: Provider = {
    provide: ThemeAssets,
    useFactory: themeAssetsFactory,
    deps: [
        [new Optional(), new SkipSelf(), ThemeAssets],
        [MatIconRegistry],
        [DomSanitizer],
    ],
};
