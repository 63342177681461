import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Observable,
  Subscription,
  Subject,
  BehaviorSubject,
} from 'rxjs';

import {
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  PasswordVisibility,
} from '../../../models/password-visibility.model';

import {
  Init,
  InitSession,
} from '../../../store/auth/auth.actions';

import {
  AuthState,
} from '../../../store/auth/auth.state';


@Component({
  selector: 'nym-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  // animations: ANIMATIONS,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();

  @HostBinding('class.nym-sign-in')
  protected classes: boolean = true;

  @Select(AuthState.problem)
  public problem$: Observable<Problem>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  public passwordMode: PasswordVisibility = PasswordVisibility.Hidden;

  public form: FormGroup;

  public message: Subject<any>;

  public messageValue: any;

  public showMessage: boolean = false;

  public get mobile(): AbstractControl {
    return this.form.get('mobile');
  }

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _cd: ChangeDetectorRef,
    private _ph: ProblemHandler,
  ) {
    this.message = new BehaviorSubject('')
    this._store.dispatch(new Init());

    this.form = this._fb.group({
      mobile: ['', [Validators.required]],
    });
  }

  public ngOnInit(): void {
    this.message.subscribe((message) => {
      this.messageValue = message;
    });
    this._subscription.add(this.problem$.subscribe((problem) => {
      this._ph.handle(problem, this.form, ['mobile']);
      this._cd.markForCheck();
    }));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public inputChanged(event: any): void {
    if(this.mobile.value === '') {
      this.showMessage = false;
    }
  }

  public submit(): any {
    this.form.setErrors(null);
    if (!this.form.valid)
      return;

    const mobile = `+${this.mobile.value.replace(/\D+/g, '')}`;
    this._store.dispatch(new InitSession(mobile)).subscribe((data) => {
      if ( data.auth.problem && data.auth.problem.status !== 404) {
        this.message.next(data.auth.problem.detail);
        this.showMessage = true;
      } else {
        this.showMessage = false;
      }
    });
  }
}
