/* tslint:disable: no-non-null-assertion */

import {
    Injectable,
    TemplateRef,
} from '@angular/core';

import {
    ComponentType,
} from '@angular/cdk/portal';

import {
    MatDialogConfig as DialogConfig,
} from '@angular/material/dialog';

import {
    DialogService,
} from './services/dialog.service';

import {
    DialogRef,
} from './dialog.ref';

import {
    ModalFactory,
} from './factories/modal.factory';

import {
    ConfirmContainer,
} from './outlets/confirm/confirm.container';

import {
    ModalContainer,
} from './outlets/modal/modal.container';

import {
    FullwidthContainer,
} from './outlets/fullwidth/fullwidth.container';

import {
    PreviewFactory,
} from './factories/preview.factory';

import {
    PreviewContainer,
} from './outlets/preview/preview.container';

// TODO(@leandro): 100vh and 100vw not working on chrome.
//                 review it in future versions
export const FULLWIDTH_DIALOG_CONFIG: any = {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '99%',
    height: '99%',
    autoFocus: false,
    panelClass: 'thm-fullwidth-dialog-pane',
};

export const PREVIEW_DIALOG_CONFIG: any = {
    disableClose: true,
    panelClass: 'thm-preview-dialog-pane',
    backdropClass: 'thm-preview-dialog-backdrop',
};

export const CONFIRM_DIALOG_CONFIG: any = {
    width: '480px',
    autoFocus: false,
    panelClass: 'thm-confirm-dialog-pane',
};

@Injectable()
export class Dialog {

    constructor(
        private _dialog: DialogService,
        private _modal: ModalFactory,
        private _preview: PreviewFactory,
    ) { }

    public openAsFullwidth<T, D = any>(
        content: ComponentType<T> | TemplateRef<T>,
        config?: DialogConfig<D>,
    ): DialogRef<T> {
        return this._dialog.open(this._modal, FullwidthContainer, content, { ...FULLWIDTH_DIALOG_CONFIG, ...config });
    }

    public openAsModal<T, D = any>(
        content: ComponentType<T> | TemplateRef<T>,
        config?: DialogConfig<D>,
    ): DialogRef<T> {
        return this._dialog.open(this._modal, ModalContainer, content, { ...config, });
    }

    public openPreview<T, D = any>(
        data: any,
    ): DialogRef<T> {
        return this._dialog.open(this._preview, PreviewContainer, null, { ...{ data: data }, ...PREVIEW_DIALOG_CONFIG });
    }

    public openConfirm<T>(
        content: ComponentType<T> | TemplateRef<T>,
        data: any,
    ): DialogRef<T> {
        return this._dialog.open(this._modal, ConfirmContainer, content, { ...{ data: data }, ...CONFIRM_DIALOG_CONFIG });
    }
}
