import {
  Pipe,
  PipeTransform,
} from '@angular/core';

import {
  SafeUrl,
} from '@angular/platform-browser';

import {
  Observable,
} from 'rxjs';

import {
  SecureImageService,
} from '../../core/services/secure-image.service';


@Pipe({
  name: 'secure',
})
export class SecureImagePipe implements PipeTransform {

  constructor(
    private urlHelperService: SecureImageService,
  ) { }

  public transform(url: string): Observable<SafeUrl> {
    return this.urlHelperService.get(url);
  }

}
