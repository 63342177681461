
import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  themeAssetsProvider,
} from './theme.assets';

import {
  ThemeAppLayoutModule,
} from './ui/layouts/app-layout/app-layout.module';

import {
  ThemeCoreModule,
} from './core/core.module';

import {
  ThemePipesModule,
} from './pipes/pipes.module';

import {
  ThemeComponentsAvatarModule,
} from './ui/components/avatar/avatar.module';

import {
  ThemeComponentsContentModule,
} from './ui/components/content/content.module';

import {
  ThemeComponentsDialogModule,
} from './ui/components/dialog/dialog.module';

import {
  ThemeComponentsLoaderModule,
} from './ui/components/loader/loader.module';

import {
  ThemeComponentsLozengeModule,
} from './ui/components/lozenge/lozenge.module';

import {
  ThemeComponentsProgressBarModule,
} from './ui/components/progress-bar/progress-bar.module';

import {
  ThemeComponentsScrollbarModule,
} from './ui/components/scrollbar/scrollbar.module';

import {
  ThemeComponentsShimmerModule,
} from './ui/components/shimmer/shimmer.module';

import {
  ThemeComponentsTableModule,
} from './ui/components/table/table.module';

import {
  ThemeComponentsThumbnailModule,
} from './ui/components/thumbnail/thumbnail.module';

import {
  ThemeDirectivesHeaderModule,
} from './ui/directives/header/header.module';

import {
  ThemeDirectivesFooterModule,
} from './ui/directives/footer/footer.module';

import {
  ThemeDirectivesInfoModule,
} from './ui/directives/info/info.module';

import {
  ThemeDirectivesSelectedModule,
} from './ui/directives/selected/selected.module';

import {
  ThemeDirectivesSizeModule,
} from './ui/directives/size/size.module';

import {
  ThemeDirectivesSectionModule,
} from './ui/directives/section/section.module';

import {
  ThemeDirectivesTitleModule,
} from './ui/directives/title/title.module';


@NgModule({
  imports: [
    CommonModule,
  ],

  exports: [
    ThemeAppLayoutModule,
    ThemeCoreModule,
    ThemePipesModule,
    ThemeComponentsAvatarModule,
    ThemeComponentsContentModule,
    ThemeComponentsDialogModule,
    ThemeComponentsLoaderModule,
    ThemeComponentsLozengeModule,
    ThemeComponentsProgressBarModule,
    ThemeComponentsScrollbarModule,
    ThemeComponentsShimmerModule,
    ThemeComponentsTableModule,
    ThemeComponentsThumbnailModule,
    ThemeDirectivesHeaderModule,
    ThemeDirectivesFooterModule,
    ThemeDirectivesInfoModule,
    ThemeDirectivesSelectedModule,
    ThemeDirectivesSectionModule,
    ThemeDirectivesSizeModule,
    ThemeDirectivesTitleModule,
  ],

  providers: [
    themeAssetsProvider,
  ],
})
export class ThemeModule { }
