import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'thm-content-header, .thm-content-header, [thm-content-header], [thmContentHeader]',
})
export class ContentHeaderDirective {

  @HostBinding('class.thm-content-header')
  @HostBinding('class.mat-h5')
  protected classes: boolean = true;

  constructor() { }

}
