import {
    Action,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';

import {
    catchError,
    finalize,
    mergeMap,
    tap,
} from 'rxjs/operators';

import {
    AgentAccountSession as Session,
    AgentAccountSessionInitiation as SessionInitiation,
    AgentsService,
    Oauth2AccessToken,
    Oauth2Service,
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    AuthFail,
    ConfirmSession,
    Init,
    InitSession,
    RefreshToken,
    SignIn,
    SignOut,
} from './auth.actions';

import {
    StoreProfileFromApi,
} from '../profile/profile.actions';

import {
    AuthService,
} from '../../services/auth.service';


export class AuthStateModel {
    public token: Oauth2AccessToken;
    public mobile: string;
    public session: Session;
    public sessionId: string;
    public problem: Problem;
    public authenticating: boolean;
}

const stateDefaults: AuthStateModel = {
    token: undefined,
    mobile: undefined,
    session: undefined,
    sessionId: undefined,
    problem: undefined,
    authenticating: undefined,
};

@State<AuthStateModel>({
    name: 'auth',
    defaults: stateDefaults,
})
export class AuthState {

    constructor(
        private _agentService: AgentsService,
        private _oauth2Service: Oauth2Service,
        private _service: AuthService,
    ) { }

    @Action(InitSession)
    public initSession(ctx: StateContext<AuthStateModel>, { mobile }: InitSession): any {

        ctx.patchState({ mobile: mobile, authenticating: true });
        return this._agentService.initSession(mobile).pipe(
            tap(({ sessionId }: SessionInitiation) => {
                ctx.patchState({ sessionId: sessionId });
            }),

            mergeMap(() =>
                ctx.dispatch(new Navigate(['/account/challenge/ipp'])),
            ),

            catchError((problem) => ctx.dispatch(new AuthFail(problem))),

            finalize(() => {
                ctx.patchState({ authenticating: false });
            }),
        );
    }

    @Action(ConfirmSession)
    public confirmSession(ctx: StateContext<AuthStateModel>, { code }: ConfirmSession): any {

        const state = ctx.getState();
        ctx.patchState({ authenticating: true });
        return this._agentService.confirmSession(state.sessionId, code).pipe(
            tap((session: Session) => {
                ctx.patchState({ session: session });
            }),

            mergeMap((session: Session) =>
                ctx.dispatch(new SignIn(session.customToken)),
            ),


            catchError((problem) => ctx.dispatch(new AuthFail(problem))),

            finalize(() => {
                ctx.patchState({ authenticating: false });
            }),
        );
    }


    @Action(RefreshToken)
    public refresh(ctx: StateContext<AuthStateModel>, { token }: RefreshToken): any {
        ctx.patchState({ token: token });
    }

    @Action(SignIn)
    public signin(ctx: StateContext<AuthStateModel>, { customToken }: SignIn): any {

        return this._oauth2Service.signinWithCustomToken(customToken.token, customToken.provider).pipe(
            tap((token: Oauth2AccessToken) => {
                ctx.patchState({ token: token });
            }),

            mergeMap(() => {
                const state = ctx.getState();
                return ctx.dispatch([new StoreProfileFromApi(state.session.account), new Navigate(['/'])]);
            }),

            catchError((problem) => {
                return ctx.dispatch(new AuthFail(problem));
            }),
        );
    }

    @Action(Init)
    public init(ctx: StateContext<AuthStateModel>): void {
        ctx.setState(stateDefaults);
    }

    @Action(SignOut)
    public signout(ctx: StateContext<AuthStateModel>): void {
        localStorage.removeItem('deviceId');
        ctx.dispatch(new Navigate(['/account/signin']));
        ctx.setState(stateDefaults);
    }

    @Action(AuthFail)
    public authFail(ctx: StateContext<AuthStateModel>, { problem }: AuthFail): void {
        ctx.patchState({ problem: problem });
    }

    @Selector()
    public static accessToken(state: AuthStateModel): string { return state.token.accessToken && state.token.accessToken; }

    @Selector()
    public static session(state: AuthStateModel): any { return state.session; }

    @Selector()
    public static mobile(state: AuthStateModel): string { return state.mobile; }

    @Selector()
    public static problem(state: AuthStateModel): Problem { return state.problem; }

    @Selector()
    public static authenticating(state: AuthStateModel): boolean { return state.authenticating; }
}
