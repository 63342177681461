/* tslint:disable: component-class-suffix */

import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';

import {
    thmDialogAnimation as thmAnimation,
} from '../../animations/dialog.animations';

import {
    DialogContainer,
} from '../dialog.container';


@Component({
    selector: 'thm-preview-dialog',
    templateUrl: './preview.container.html',
    styleUrls: ['./preview.container.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        thmAnimation.dialogContainer,
        thmAnimation.downloadingImage,
        thmAnimation.slideDown,
    ],
})
export class PreviewContainer extends DialogContainer implements OnInit {

    private _index: number = 0;
    protected downloading: boolean = true;

    protected get images(): any { return this.data.images || []; }
    protected get image(): any { return this.images[this._index]; }

    @HostBinding('class.thm-preview-dialog')
    public get classes(): boolean { return true; }

    public ngOnInit(): void {
        this._index = this.data.index;
        this._index = Math.max(this._index, 0);
        this._index = Math.min(this._index, this.images.length - 1);
    }

    protected loaded(event: Event): void {
        this.downloading = false;
    }

    protected isFirst(): boolean {
        return this._index === 0;
    }

    protected isLast(): boolean {
        return this._index === this.images.length - 1;
    }

    protected back(): any {
        this.downloading = true;
        this._index = Math.max(this._index - 1, 0);
    }

    protected next(): any {
        this.downloading = true;
        this._index = Math.min(this._index + 1, this.images.length - 1);
    }

}
