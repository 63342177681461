type Status =
    | 'pending'
    | 'rejected'
    | 'processing'
    | 'cancelling'
    | 'cancelled'
    | 'settled'
    | 'declined'
    | 'error'
    | undefined;

type Category =
| 'credit'
| 'debit'
| 'card_issuance'
| 'card_reload'
| 'transfer'
| undefined;

export class WalletTransfer {

    public id: string;
    public accountId:string;
    public receiver: string;
    public receiverID: string;
    public friendlyId: string;
    public status: Status;
    public currency: string;
    public amount: number;
    public fees: number;
    public total: number;
    public limit: number;

    constructor(ob?: Partial<WalletTransfer>) {

        this.id = undefined;
        this.accountId=undefined;
        this.receiver = undefined;
        this.receiverID = undefined;
        this.friendlyId = undefined;
        this.status = undefined;
        this.currency = undefined;
        this.amount = undefined;
        this.fees = undefined;
        this.total = undefined;
        this.limit = undefined;

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.accountId !== undefined) { this.accountId = ob.accountId; }
        if (ob.receiver !== undefined) { this.receiver = ob.receiver; }
        if (ob.receiverID !== undefined) { this.receiverID = ob.receiverID; }
        if (ob.friendlyId !== undefined) { this.friendlyId = ob.friendlyId; }
        if (ob.status !== undefined) { this.status = ob.status; }
        if (ob.currency !== undefined) { this.currency = ob.currency; }
        if (ob.amount !== undefined) { this.amount = ob.amount; }
        if (ob.fees !== undefined) { this.fees = ob.fees; }
        if (ob.total !== undefined) { this.total = ob.total; }
        if (ob.limit !== undefined) { this.limit = ob.limit; }
    }
}

