import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'thm-toolbar-spacer, .thm-toolbar-spacer, [thm-toolbar-spacer], [thmToolbarSpacer]',
})
export class ContentToolbarSpacerDirective {

  @HostBinding('class.thm-toolbar-spacer')
  private _classes: boolean = true;

  constructor() { }

}
