import {
    Injectable,
} from '@angular/core';

import {
    Oauth2RefreshService,
} from '@michel.freiha/ng-sdk';

import {
    Store,
} from '@ngxs/store';

import {
    RefreshToken,
    SignOut,
} from '../store/auth/auth.actions';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(
        private _refreshService: Oauth2RefreshService,
        private _store: Store,
    ) {
        this._refreshService.token$.subscribe((token) => {
            this._store.dispatch(new RefreshToken(token));
        });

        this._refreshService.logout$.subscribe(() => {
            this._store.dispatch(new SignOut());
        });
    }
}
