import {
  NgxsConfig,
} from '@ngxs/store/src/symbols';

import {
  WalletLimitsState,
} from './wallet-limits/wallet-limits.state';

import {
  WalletTransactionsState,
} from './wallet-transactions/wallet-transactions.state';

export const STATES_MODULES = [WalletLimitsState, WalletTransactionsState];

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
  /**
   * Run in development mode. This will add additional debugging features:
   * - Object.freeze on the state and actions to guarantee immutability
   * todo: you need set production mode
   * import { environment } from '@env';
   * developmentMode: !environment.production
   */
  developmentMode: true,
};

export const DEVTOOLS_REDUX_CONFIG: any = {
  /**
   * Whether the dev tools is enabled or note. Useful for setting during production.
   * todo: you need set production mode
   * import { environment } from '@env';
   * disabled: environment.production
   */
  disabled: false,
};

export const LOGGER_CONFIG: any = {
  /**
   * Disable the logger. Useful for prod mode..
   * todo: you need set production mode
   * import { environment } from '@env';
   * disabled: environment.production
   */
  disabled: false,
};
