import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'footer, thm-footer, .thm-footer, [thm-footer], [thmFooter]',
})
export class FooterDirective {

  @HostBinding('class.thm-footer')
  public classes: boolean = true;

  constructor() { }

}
