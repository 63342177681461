import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  AuthState,
} from '../../../store/auth/auth.state';



@Component({
  selector: 'nym-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit, OnDestroy {

  @HostBinding('class.auth')
  protected classes: boolean = true;

  @Select(AuthState.session)
  public session$: Observable<any>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  constructor() { }

  public ngOnInit(): void { }

  public ngOnDestroy(): void {
  }
}
