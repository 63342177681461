import {
    Injectable,
} from '@angular/core';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    MatIconRegistry,
} from '@angular/material/icon';


const ASSETS_FOLDER = 'assets/console/';
const LOGO_FOLDER = 'assets/logo/';

@Injectable({
    providedIn: 'root',
})
export class ConsoleAssets {

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {

        iconRegistry.addSvgIconInNamespace('app', 'logo', sanitizer.bypassSecurityTrustResourceUrl(LOGO_FOLDER + 'logo.svg'));
     }

}
