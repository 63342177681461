import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  RouterModule,
} from '@angular/router';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import {
  NymAuthModule,
} from '@nymos/auth';

import {
  ThemeModule,
} from '@nymos/theme';

import {
  NotificationModule,
} from './notification/notification.module';

import {
  StoreModule,
} from './store/store.module';

import {
  NumberOnlyDirective,
} from './utils/directives/number-only.directive';

import {
  CurrencyDirective,
} from './utils/directives/currency.directive';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

export const DIRECTIVES = [
  CurrencyDirective,
  NumberOnlyDirective,
];

@NgModule({
  imports: [
    StoreModule,
  ],
})
export class SharedRootModule {
  constructor(
    @Optional() @SkipSelf() parentModule: SharedRootModule,
  ) {
    if (parentModule) throw new Error('SharedRootModule is already loaded. Import it in the AppModule only');
  }
}

@NgModule({

  declarations: [
    ...DIRECTIVES,
  ],

  imports: [
    CommonModule,
  ],

  exports: [
    ...DIRECTIVES,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NymAuthModule,
    NotificationModule,
    ThemeModule,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedRootModule,
      providers: [
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
      ],
    };
  }
}
