/* tslint:disable:member-ordering */

import {
  Action,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

import {
  catchError,
  finalize,
  tap,
} from 'rxjs/operators';

import {
  Problem,
  Wallet,
  WalletsService,
} from '@michel.freiha/ng-sdk';

import {
  SignOut,
} from '@nymos/auth';

import {
  NotificationCenter,
} from '../../notification/services/notification.service';

import {
  WalletBalance,
} from '../../models/wallet-balance.model';

import {
  WalletBalanceBuilder,
} from '../../builders/wallet-balance.builder';

import {
  FailFromApi,
  LoadBalanceFromTopupPage,
  RefreshBalanceFromTopupPage,
} from './wallet-limits.actions';

import {
  Notifications,
} from './wallet-limits.notifications';


export interface WalletLimitsStateModel {
  wallets: Wallet[];
  loading: boolean;
  problem: Problem;
}

const stateDefaults: WalletLimitsStateModel = {
  wallets: undefined,
  loading: undefined,
  problem: undefined,
};

@State<WalletLimitsStateModel>({
  name: 'wallet_limits',
  defaults: stateDefaults,
})
export class WalletLimitsState {

  @Selector()
  public static balance(state: WalletLimitsStateModel): WalletBalance {
    return new WalletBalanceBuilder().withWallets(state.wallets).build();
  }

  @Selector()
  public static problem(state: WalletLimitsStateModel): Problem {
    return state.problem;
  }

  @Selector()
  public static loading(state: WalletLimitsStateModel): boolean {
    return state.loading;
  }

  constructor(
    private _nc: NotificationCenter,
    private _walletService: WalletsService,
  ) { }

  @Action(SignOut)
  public reset(ctx: StateContext<WalletLimitsStateModel>): any {
    ctx.setState(stateDefaults);
  }

  @Action(LoadBalanceFromTopupPage, { cancelUncompleted: true })
  public loadLimits(ctx: StateContext<WalletLimitsStateModel>): any {

    ctx.patchState({ loading: true, wallets: [] });

    return this._loadBalance(ctx).pipe(
      finalize(() => ctx.patchState({ loading: false })),
    );
  }

  @Action(RefreshBalanceFromTopupPage)
  public refreshBalance(ctx: StateContext<WalletLimitsStateModel>): any {
    return this._loadBalance(ctx);
  }

  private _loadBalance(ctx: StateContext<WalletLimitsStateModel>): any {

    return this._walletService.loadWallets().pipe(

      tap((wallets: Wallet[]) => {
        ctx.patchState({wallets: wallets});
      }),

      catchError((problem) => {
        return ctx.dispatch(new FailFromApi(problem));
      }),
    );
  }
}
