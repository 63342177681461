import 'hammerjs';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import {
  CanColor,
  CanColorCtor,
  mixinColor,
  ThemePalette,
} from '@angular/material/core';


import {
  coerceBooleanProperty,
  coerceCssPixelValue,
  coerceNumberProperty,
} from '@angular/cdk/coercion';

import {
  CanShimmer,
  CanShimmerCtor,
  mixinShimmer,
} from '../../../../../core/core';

export class AvatarBase {
  constructor(public _elementRef: ElementRef, public _renderer: Renderer2) { }
}

export const _AvatarMixinBase:
  CanShimmerCtor & CanColorCtor & typeof AvatarBase =
  mixinColor(mixinShimmer(AvatarBase));

const DEFAULT_SIZE: number = 32;
const DEFAULT_COLOR: ThemePalette = 'primary' as ThemePalette;

@Component({
  selector: 'thm-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  inputs: ['color', 'thmShimmer', 'thmShimmerShape'], // tslint:disable-line:no-inputs-metadata-property
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent extends _AvatarMixinBase
  implements OnInit, OnChanges, OnDestroy, CanColor, CanShimmer {

  private _hide: boolean = false;
  private _element: any;

  @HostBinding('class.thm-avatar')
  public classes: boolean = true;

  @Input()
  public name: string;

  @Input()
  public email: string;

  @Input()
  public photo: string;

  @Input()
  public size: string;

  @Input()
  public rgb: string;

  @Input()
  public badgeColor: ThemePalette = 'primary';

  @Input()
  public badgeIcon: string;

  @Input()
  public get noTooltip(): boolean { return this._hide; }
  public set noTooltip(value: boolean) { this._hide = coerceBooleanProperty(value); }

  protected get badgeClass(): string {
    return this.badgeColor ? `mat-${this.badgeColor}` : '';
  }

  protected get tooltip(): string {
    return !this._hide ? this.name || this.email || '' : '';
  }

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
  ) {
    super(elementRef, renderer);
    this.color = DEFAULT_COLOR;
    this.size = DEFAULT_SIZE + 'px';
    this.thmShimmerShape = `c:${DEFAULT_SIZE}`;
    this._element = elementRef.nativeElement;
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    const size = changes['size'];
    const rgb = changes['rgb'];

    if (size) {
      if (!size.isFirstChange())
        this._renderer.removeClass(this._element, `thm-avatar-${this._toMedia(size.previousValue)}`);
      this._renderer.addClass(this._element, `thm-avatar-${this._toMedia(size.currentValue)}`);
      this._renderer.setStyle(this._element, 'padding', this._toPadding(size.currentValue));

      const value = this._toNumber(size.currentValue);
      this.thmShimmerShape = `c:${value}`;
    }

    if (rgb) {
      this._renderer.setStyle(this._element, 'background-color', rgb.currentValue);
    }

  }

  private _toNumber(change: string): number {
    return coerceNumberProperty(change.replace('px', ''));
  }

  private _toPadding(size: string): string {
    const value = this._toNumber(size);
    const padding = Math.floor(value / DEFAULT_SIZE);
    return coerceCssPixelValue(Math.max(padding, 1));
  }

  private _toMedia(size: string): 'small' | 'medium' | 'large' {
    const value = this._toNumber(size);

    if (value <= 36)
      return 'small';

    if (value >= 64)
      return 'large';

    return 'medium';
  }

}
